import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditEarned'
})
export class CreditEarnedPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args) return "-";
    switch (args) {
      case 'IP':
        return "-";
      case 'I':
        return "-";
      case 'W':
        return "-";
      case 'F':
        return 0;
      case 'WF':
        return 0
      default:
        return value
    }
  }

}

