import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Pages } from "./../dummy/pages";
import { enrollStatusObj, preEnrollStatus, paymentStatus, invoiceTypesObj } from "../dummy/status";

@Injectable({
  providedIn: "root"
})
export class DataService {
  constructor(private db: AngularFirestore) { }
  holidayRef() {
    return this.db.collection<any>("public_holiday");
  }
  holidayYearRef(start, end) {
    return this.db.collection<any>("public_holiday", ref =>
      ref.where("from_date_key", ">=", Number(start)).where("from_date_key", "<=", Number(end))
    );
  }
  holidayFetchRef(lastVisible: any) {
    return this.db.collection("public_holiday", ref =>
      ref
        .orderBy("page_key", "desc")
        .startAfter(lastVisible.page_key)
        .limit(Pages.size)
    );
  }

  untranscriptFireRef(studentKey: string, admissionKey: string) {
    return this.db.collection<any>("academic_student_transcript")
      .doc(studentKey)
      .collection("admission")
      .doc(admissionKey)
      .collection<any>("courses");
  }
  updateInvoiceNoAndPucID(puc_id, callback) {
    this.settingFireStore()
      .get()
      .then(config => {
        const setting = config.data();
        if (puc_id) {
          this.sysSetting()
            .update({
              invoice_shufit: setting.invoice_shufit + 1
            })
            .then(() => {
              callback(true, setting);
            })
            .catch(error => {
              callback(false, error);
            });
        } else {
          this.sysSetting()
            .update({
              invoice_shufit: setting.invoice_shufit + 1,
              puc_id: setting.puc_id + 1
            })
            .then(() => {
              callback(true, setting);
            })
            .catch(error => {
              callback(false, error);
            });
        }
      })
      .catch(error => {
        callback(false, error);
      });
  }

  settingFireStore() {
    return this.firestore()
      .collection("testing_options")
      .doc("general");
  }

  sysSetting() {
    return this.db.collection("testing_options").doc("general");
  }

  invoiceFireRef() {
    return this.firestore().collection("invoices");
  }

  prepaidFireRef() {
    return this.firestore().collection("prepaid_movement");
  }

  studentFireDocRef() {
    return this.firestore().collection("students");
  }

  studentAccountFireDocRef() {
    return this.firestore().collection("student_accounts");
  }

  testingFireRef() {
    return this.firestore().collection("testing");
  }

  testingRef() {
    return this.db.collection("testing");
  }


  studentPreEnrollFireDocRef(termKey: string) {
    return this.firestore().collection("academics_term").doc(termKey).collection("all_pre_enrollments");
  }

  academicTermDocRef() {
    return this.db.collection("academics_term");
  }


  academicShiftRef() {
    return this.db.collection("academics_shift", ref => ref.orderBy("order"));
  }

  admissionRef(studentKey) {
    return this.db.collection("academics_major_admission", ref => ref
      .where("student.key", "==", studentKey)
      .where("status.key", "==", 1)
      .orderBy("page_key", "desc"));
  }
  batchLevelStudentRef(batchKey, studentKey) {
    return this.db.collection("institute_training_level_batch")
      .doc(batchKey).collection("students", ref => ref.where("student.key", "==", studentKey))
  }

  batchLevelFireRef() {
    return this.firestore().collection("institute_training_level_batch")
  }
  instituteRef() {
    return this.db.collection("institutes", ref => ref.orderBy("page_key"));
  }

  instituteTermRef(instituteKey) {
    return this.db.collection<any>("academics_term", ref => ref
      .where("institute.key", "==", instituteKey)
      .orderBy("page_key", "desc")
      .limit(Pages.size / 2)
    );
  }


  // termAvailableRef() {
  //   return this.db.collection("academics_term", ref => ref
  //     .where("termstatus.key", ">=", 2)
  //     .where("termstatus.key", "<=", 2.9)
  //     .orderBy("termstatus.key")
  //   );
  // }



  termAvailableRef() {
    return this.db.collection("academics_term", ref => ref
      .orderBy("page_key", "desc").limit(Pages.size/2)

    );
  }


  paymentTermRef(key: string) {
    return this.db.collection("academic_environment").doc(key);
  }

  batchLevelMovementFireRef() {
    return this.firestore().collection("institute_batch_movement")
  }
  studentFireS(key: string) {
    return this.firestore().collection("students").doc(key);
  }
  instituteNoScheduleStudentRef(studentKey) {
    return this.db.collection("institute_no_schedule", ref => ref.where("student.key", "==", studentKey))
  }

  instituteNoScheduleStudentFireRef() {
    return this.firestore().collection("institute_no_schedule")
  }
  scheduleInProgressStudentRef(studentKey) {
    return this.db.collection("schedule_in_progress", ref => ref.where("student.key", "==", studentKey))
  }
  scheduleInProgressFireRef() {
    return this.firestore().collection("schedule_in_progress")
  }

  admissionDocRef(admissionKey) {
    return this.db.collection<any>("academics_major_admission").doc<any>(admissionKey)
  }

  batchLevelRef() {
    return this.db.collection("institute_training_level_batch")
  }


  schedulesMajorRef(termKey, majorKey) {
    return this.db
      .collection("academics_term")
      .doc(termKey)
      .collection<any>("schedules", ref =>
        ref
          .where("major.key", "==", majorKey)
          .where("isHeader", "==", true)
          .orderBy("session.shift.order")
      );
  }

  scheduleSearchRef(termKey, field: string, search: any) {
    if (search.key) {
      return this.db
        .collection("academics_term")
        .doc(termKey)
        .collection("schedules", ref =>
          ref.where("key", "==", search.key).limit(Pages.size)

        );
    }
    return this.db
      .collection("academics_term")
      .doc(termKey)
      .collection("schedules", ref =>
        ref
          .where("isBatch", "==", true)
          .where("isHeader", "==", true)
          .where(field, ">=", search)
          .orderBy(field)
          .limit(Pages.size)
      );
  }

  scheduleRef(term) {
    return this.db
      .collection("academics_term")
      .doc(term)
      .collection("schedules", ref =>
        ref
          .where("isBatch", "==", true)
          .where("isHeader", "==", true)
          .orderBy("schedule_subject.subject.name").limit(12)
      );
  }
  schedulesAcademicMajorRef(termKey, majorKey) {
    return this.db
      .collection("academics_term")
      .doc(termKey)
      .collection<any>("academic_schedule_batches", ref =>
        ref
          .where("major.key", "==", majorKey)
          .where("isHeader", "==", true)
          .orderBy("session.shift.order")
      );
  }

  scholarshipByStudent(Key) {
    return this.db.collection("scholarships", ref =>
      ref.where("student.key", "==", Key)
    );
  }

  invoiceItemsRef(studentKey: string, headerRef: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref.where("headerRef", "==", headerRef));
  }

  studentScholarshipByProgram(studentKey: string, programKey: string) {
    return this.db
      .collection("students")
      .doc(studentKey)
      .collection("scholarships", ref =>
        ref.where("program.key", "==", programKey)
      );
  }

  invoiceItemsByStudentKeyRef(studentKey: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref =>
      ref
        .where("isHeader", "==", true)
        .orderBy("received_date_key", "desc")
        .limit(1)
    );
  }

  wingInvoiceRef() {
    return this.db.collection("invoice_wing");
  }

  wingInvoiceFireRef() {
    return this.firestore().collection("invoice_wing");
  }

  invoicesStudentRef(studentKey: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref.where("isHeader", "==", true));
  }

  invoiceAllByStudentKeyRef(studentKey: string) {
    return this.db.collection<any>("invoices", ref => ref.where("student.key", "==", studentKey).where("isHeader", "==", true).orderBy("page_key", "desc"));
  }

  studentCourseRef(termKey, studentKey) {
    return this.db
      .collection("academics_term")
      .doc(termKey)
      .collection("enrollment", ref =>
        ref
          .where("enroll_status.key", "==", 2)
          .where("student.key", "==", studentKey)
          .orderBy("page_key")
      );
  }

  studentScheduleRef(termKey, studentKey) {
    return this.db
      .collection("students")
      .doc(studentKey)
      .collection("enrollment", ref =>
        ref
          .where("enroll_status.key", "==", enrollStatusObj.add.key)
          .where("term.key", "==", termKey)
          .where("isShow", "==", true)
          .orderBy("page_key")
      );
  }

  generationBatchRef(majorKey, generationKey, shiftKey) {
    return this.db.collection("academic_generation_batch", ref => ref
      .where("shift.key", "==", shiftKey)
      .where("major.key", "==", majorKey)
      .where("puc_generation.key", "==", generationKey)
      .orderBy("puc_generation.generation", "desc")
    )
  }

  studentCurrentScheduleRef(termKey, studentKey, shiftKey) {
    return this.db.collection("academics_term").doc(termKey).collection("schedules", ref => ref
      .where("session.shift.key", "==", shiftKey)
      .where("enrollmentStudentKey", "array-contains", studentKey)
      .orderBy("schedule_subject.subject.name")
    );
  }
  // 7Rv9hVuYfh0zzyWYPiom
  studentCurrentScheduleTermRef(termKey, studentKey) {
    return this.db.collection("academics_term").doc(termKey).collection("schedules", ref => ref
      .where("enrollmentStudentKey", "array-contains", studentKey)
      .orderBy("schedule_subject.subject.name")
    );
  }


  studentBatchScheduleRef(termKey, batchKey, shiftKey) {
    return this.db.collection("academics_term").doc(termKey).collection("schedules", ref => ref
      .where("session.shift.key", "==", shiftKey)
      .where("batchMember", "array-contains", batchKey)
      .orderBy("schedule_subject.subject.name"));
  }


  studentAllScheduleRef(termKey, majorKey, shiftKey, lastVisible) {
    if (majorKey) {
      if (lastVisible) {
        return this.db.collection("academics_term").doc(termKey).collection("schedules", ref => ref
          .where("session.shift.key", "==", shiftKey)
          .where("majorMember", "array-contains", majorKey)
          .orderBy("page_key")
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
        );
      }
      return this.db.collection("academics_term").doc(termKey).collection("schedules", ref => ref
        .where("session.shift.key", "==", shiftKey)
        .where("majorMember", "array-contains", majorKey)
        .orderBy("page_key")
        .limit(Pages.size)
      );
    }
    if (lastVisible) {
      return this.db.collection("academics_term").doc(termKey).collection("schedules", ref => ref
        .where("session.shift.key", "==", shiftKey)
        .orderBy("page_key")
        .startAfter(lastVisible.page_key)
        .limit(Pages.size)
      );
    }
    return this.db.collection("academics_term").doc(termKey).collection("schedules", ref => ref
      .where("session.shift.key", "==", shiftKey)
      .orderBy("page_key")
      .limit(Pages.size)
    );

  }


  studentMajorScheduleRef(termKey, majorKey, shiftKey) {
    return this.db.collection("academics_term").doc(termKey).collection("schedules", ref => ref
      .where("session.shift.key", "==", shiftKey)
      .where("majorMember", "array-contains", majorKey)
      .orderBy("schedule_subject.subject.name")
    );
  }

  instituteBatchRef(batchKey, termKey) {
    return this.db.collection("institute_training_level_batch").doc(batchKey).collection("schedules", ref => ref.where("term.key", "==", termKey));
  }

  studentEnrollmentRef(termKey, studentKey) {
    return this.db
      .collection("students").doc(studentKey).collection("enrollment"
        , ref => ref.where("term.key", "==", termKey)
      );
  }

  studentTranscriptRef(studentKey, admissionKey) {
    return this.db
      .collection<any>("academic_student_transcript")
      .doc(studentKey)
      .collection("admission")
      .doc(admissionKey)
      .collection<any>("courses", ref => ref.orderBy("term.page_key"));
  }

  studentTranscriptDocRef(studentKey, admissionKey, enrollKey) {
    return this.db
      .collection<any>("academic_student_transcript")
      .doc(studentKey)
      .collection("admission")
      .doc(admissionKey)
      .collection("courses")
      .doc(enrollKey);
  }

  studentAdmissionRef(studentKey) {
    return this.db.collection<any>("academics_major_admission", ref =>
      ref
        .where("student.key", "==", studentKey)

    );
  }

  surveyCourse(studentKey, termKey, subjectKey, courseKey) {
    return this.db.collection<any>("survey_course", ref =>
      ref.where("studentKey", "==", studentKey).where("termKey", "==", termKey).where("subjectKey", "==", subjectKey).where("course.key", "==", courseKey)
    );
  }


  instituteActiveTermRef(key: string) {
    return this.db.collection("academic_environment").doc<any>(key);
  }

  selectedAdmissionRef(admissionKey) {
    return this.db
      .collection<any>("academics_major_admission")
      .doc(admissionKey);
  }

  studentAccountRef(uid) {
     return this.db.collection("student_accounts", ref =>
      ref.where("key", "==", uid).limit(1)
    );
  }

  studentRef(uid) {
    return this.db.collection("students").doc<any>(uid);
  }

  studentEnrollmentDocsRef(studentKey, admissionKey) {
    return this.db
      .collection("students")
      .doc(studentKey)
      .collection("enrollment", ref =>
        ref
          .where("isPaid", "==", false)
          .where("enroll_status.key", "==", enrollStatusObj.add.key)
          .where("program.admissionKey", "==", admissionKey)
          .orderBy("page_key")
      );
  }

  campusRef() {
    return this.db.collection("campus", ref =>
      ref
        .where("status.key", "==", 1)
        .orderBy("name")
        .limit(5)
    );
  }

  cashierRef() {
    return this.db.collection("users", ref =>
      ref.where("role.key", "==", 3).orderBy("name")
    );
  }

  studentDocument(key: string) {
    return this.db.collection("students").doc(key);
  }

  onlineTesting(key: string) {
    return this.db.collection("online_testing").doc(key);
  }

  studentInvoiceByAdmission(admissionKey: string, studentKey: string, invoiceKey) {
    if (invoiceKey) {
      return this.db.collection("students")
        .doc(studentKey)
        .collection("invoices", ref => ref
          .where("headerRef", "==", invoiceKey)
          .where("isPaid.key", "==", paymentStatus.unpaid.key)
        );
    }
    else {
      return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref
        .where("isEnrollVerify", "==", true)
        .where("isPaid.key", "==", paymentStatus.unpaid.key)
        // .where("program.admissionKey", "==", admissionKey)
        .orderBy("page_key")
      );
    }
  }




  academicRef() {
    return this.db
      .collection("academic_environment")
      .doc<any>("academic_environment");
  }
  englishTerRef() {
    return this.db
      .collection("academic_environment")
      .doc("DBrSzLY3UQ2kEgF50nfU");
  }
  configRef() {
    return this.db.collection("testing_options").doc<any>("general");
  }
  studentFireRef(studentKey) {
    return this.firestore()
      .collection("students")
      .doc(studentKey)
      .collection("schedules");
  }

  academicEnrollmentFireRef() {
    return this.firestore().collection("academic_enrollment");
  }

  academicTermFireRef(termKey, batchKey) {
    return this.firestore()
      .collection("academics_term")
      .doc(termKey)
      .collection("schedules")
      .doc(batchKey)
      .collection("students");
  }

  preEnrollmentFireRef() {
    return this.firestore().collection("pre_enrollments");
  }

  academicTermFireDocRef() {
    return this.firestore().collection("academics_term");
  }

  academicEnrollmentRef() {
    return this.firestore().collection("academic_enrollment");
  }

  transcriptFireRef() {
    return this.firestore().collection("academic_student_transcript");
  }

  termFireRef() {
    return this.firestore().collection("academics_term");
  }

  academicTermPreEnrollmentFireRef(termKey) {
    return this.db
      .collection("academics_term")
      .doc(termKey)
      .collection("pre_enrollments");
  }

  academicTermPreEnrollmentBatchRef(termKey) {
    return this.firestore()
      .collection("academics_term")
      .doc(termKey)
      .collection("pre_enrollments");
  }

  academicTermPreEnrollmentStudentRef(termKey) {
    return this.firestore()
      .collection("academics_term")
      .doc(termKey)
      .collection("request_enrollments");
  }

  studentPreEnrollmentRef(termKey: string, studentKey: string) {
    return this.db
      .collection("students")
      .doc(studentKey)
      .collection("pre_enrollments", ref =>
        ref
          .where("term.key", "==", termKey)
          .where("status", "==", preEnrollStatus.pending)
          .where("isRequest", "==", false)
          .orderBy("create_date")
      );
  }

  studentPreEnrollmentRequestRef(termKey, studentKey) {
    return this.db
      .collection("students").doc(studentKey).collection("pre_enrollments", ref =>
        ref
          .where("term.key", "==", termKey)
          .where("status", "==", preEnrollStatus.pending)
          .where("isRequest", "==", true)
          .orderBy("create_date")
      );
  }

  preEnrollmentApprovedRef(termKey, studentKey) {
    return this.db
      .collection("academics_term")
      .doc(termKey)
      .collection("pre_enrollments", ref =>
        ref
          .where("student.key", "==", studentKey)
          .where("status", "==", preEnrollStatus.pending)
          .where("isRequest", "==", true)
          .orderBy("create_date")
      );
  }

  studentTranscriptFireRef(studentKey, admissionKey) {
    return this.firestore()
      .collection("academic_student_transcript")
      .doc(studentKey)
      .collection("admission")
      .doc(admissionKey)
      .collection("courses");
  }


  registerScholarshipExam() {
    return this.firestore().collection("register_scholarship_exam")
  }


  dbRef() {
    return this.db;
  }

  batch() {
    return this.db.firestore.batch();
  }

  firestore() {
    return this.db.firestore;
  }

  createId() {
    return this.db.createId();
  }
}
