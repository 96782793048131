import { MappingService, pushToArray } from "./../services/mapping.service";
import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun, toJS } from "mobx";
import { Injectable } from "@angular/core";
import { ConvertService } from "../services/convert.service";
import * as moment from "moment";

@Injectable()
export class Transcript {
  @observable
  public empty = false;
  @observable
  public admissions = null
  @observable
  public data = null;
  @observable
  public loading = false;
  @observable
  public academic = null;
  @observable
  public process = false;
  @observable
  public term = null;
  @observable
  public student = null;
  @observable
  public studentTerm = null;
  @observable
  public creditAttempt = null;
  @observable
  public creditEarned = null;
  @observable
  public totalPoint = 0;
  @observable
  public totalCredit = 0;
  @observable
  public totalGPA = 0;
  @observable
  public selectedAdmission = null;
  @observable
  public transfer = null;
  @observable public admissionDate = null;
  @observable public unOfficial = null;
  @observable public successDate = null;

  constructor(private ds: DataService) { }

  @action
  fetchTranscriptPrint(studentKey, admissionKey, status: number, callback) {
    this.ds
      .studentTranscriptRef(studentKey, admissionKey).valueChanges().subscribe(snapshots => {
        const items = snapshots.filter(m => m.isShow && m.status.key === status && m.courseCode !== 'TRANA07');
        const transfers = snapshots.filter(m => m.courseCode === 'TRANA07');
        if (transfers.length > 0) this.transfer = transfers[0];
        else this.transfer = null;
        const result = MappingService.uniqOnTop(items, "courseName", "page_key");
        this.data = result;
        const subjectSEarned = result.filter(m => m.overall_grade !== 'IP' && m.overall_grade !== 'I' && m.overall_grade !== 'W');
        this.creditAttempt = MappingService.sumCreditAttempted(result);
        const subjectEarned = subjectSEarned;
        this.totalCredit = MappingService.sumCreditCalculator(subjectEarned);
        this.creditEarned = MappingService.sumCreditEarned(subjectEarned);
        const subjectTotalPoint = subjectEarned.map(item => { return { total: item.gradePoint * item.credits_calculator } });
        this.totalPoint = MappingService.sumTotalPoint(subjectTotalPoint);
        this.totalGPA = this.totalPoint / this.totalCredit;
        const t = MappingService.groupBy(result, "term.key", "term.startDateKey");
        this.studentTerm = t;
        this.empty = result.length === 0;
        callback(snapshots)
      });
  }

  @action
  fetchTranscript(studentKey, admissionKey, status: number) {
    this.loading = true;
    this.ds
      .studentTranscriptRef(studentKey, admissionKey).valueChanges().subscribe(async snapshots => {
        let items = snapshots.filter(m => m.isShow && m.isApproval && m.status.key === status && m.courseCode !== 'TRANA07');
        let studentTransfer = snapshots.filter(m => m.courseCode === 'TRANA07');
        if (studentTransfer.length > 0) {
          studentTransfer = MappingService.orderByDesc(studentTransfer, "page_key");
          this.transfer = studentTransfer[0];
        }
        else this.transfer = null;
        items = items.map(m => ({ ...m, cid: ConvertService.numberOnly(m.courseCode) }))
        const tempList = items;
        // const termCourses = [];
        // items.forEach(async m => {
        //   const surveyCourse = pushToArray(await this.ds.surveyCourse(studentKey, m.term.key, m.subject.subject.key, m.schedule.key).get().toPromise());
        //   const rows = tempList.filter(i => i.term.key === m.term.key && m.courseCode === i.courseCode);
        //   if (rows.length > 1) {
        //     const rm = rows.filter(m => m.credits_calculator > 0)
        //     if (rm.length > 0) termCourses.push(rm[0])
        //     else termCourses.push({ ...rows[0], surveyCourse:surveyCourse })
        //   }
        //   else {
        //     termCourses.push({...m, surveyCourse:surveyCourse });
        //   }
        // })
        // console.log('termCourses', termCourses)
        const coursePromises = items.map(async m => {
          const surveyCourse = pushToArray(await this.ds.surveyCourse(studentKey, m.term.key, m.subject.subject.key, m.schedule.key).get().toPromise());
          const rows = tempList.filter(i => i.term.key === m.term.key && m.courseCode === i.courseCode);
          const surveyCourseData = surveyCourse.length > 0 ? surveyCourse[0] : null;

          if (rows.length > 1) {
            const rm = rows.filter(m => m.credits_calculator > 0);
            return rm.length > 0 ? rm[0] : { ...rows[0], surveyCourse: surveyCourseData };
          }
          return { ...m, surveyCourse: surveyCourseData };
        });

        // Wait for all promises to resolve
        const termCourses = await Promise.all(coursePromises);
        const result = MappingService.uniqOnTop(termCourses, "courseName", "term.startDateKey");
        this.data = result;
        if (this.data.length > 0) {
          const orderData = MappingService.orderBy(this.data, 'term.startDateKey');
          this.admissionDate = orderData[0].term.startterm;
        }
        const subjectSEarned = result.filter(m => m.overall_grade !== 'IP' && m.overall_grade !== 'I' && m.overall_grade !== 'W');
        this.creditAttempt = MappingService.sumCreditAttempted(result);
        const subjectEarned = subjectSEarned;
        this.totalCredit = MappingService.sumCreditCalculator(subjectEarned);
        this.creditEarned = MappingService.sumCreditEarned(subjectEarned);
        const subjectTotalPoint = subjectEarned.map(item => { return { total: item.gradePoint * item.credits_calculator } });
        this.totalPoint = MappingService.sumTotalPoint(subjectTotalPoint);
        this.totalGPA = this.totalPoint / this.totalCredit;

        const subjectPointP = subjectEarned.filter(m => m.overall_grade === 'P')
        if (subjectPointP && subjectPointP.length > 0) {
          const creditEarnedTotal = ConvertService.sum(subjectPointP, "credits_earned")
          this.totalGPA = this.totalPoint / (this.totalCredit - creditEarnedTotal);
        }

        const t = MappingService.groupBy(result, "term.key", "term.startDateKey");
        this.studentTerm = t;
        this.empty = result.length === 0;
        this.loading = false;

      });
  }




  @action
  fetchTranscriptUnofficial(studentKey, admissionKey, status: number) {
    this.loading = true;
    this.ds.untranscriptFireRef(studentKey, admissionKey).valueChanges().subscribe(snapshots => {
      let items = snapshots.filter(m => m.isShow && m.isApproval && m.courseCode !== 'TRANA07');
      let studentTransfer = snapshots.filter(m => m.courseCode === 'TRANA07');
      if (studentTransfer.length > 0) {
        studentTransfer = MappingService.orderByDesc(studentTransfer, "page_key");
        this.transfer = studentTransfer[0];
      }
      else this.transfer = null;
      items = items.map(m => ({ ...m, cid: ConvertService.numberOnly(m.courseCode) }))
      const tempList = items;
      const termCourses = [];
      items.forEach(m => {
        const rows = tempList.filter(i => i.term.key === m.term.key && m.courseCode === i.courseCode);
        if (rows.length > 1) {
          const rm = rows.filter(m => m.credits_calculator > 0)
          if (rm.length > 0) termCourses.push(rm[0])
          else termCourses.push(rows[0])
        }
        else {
          termCourses.push(m);
        }
      })
      // const result = MappingService.uniqOnTop(termCourses, "courseName", "term.startDateKey");
      const result = MappingService.orderBy(termCourses, "term.startDateKey");
      this.unOfficial = result;
      if (this.unOfficial.length > 0) {
        const orderData = MappingService.orderBy(this.unOfficial, 'term.startDateKey');
        this.admissionDate = orderData[0].term.startterm;
        const yyyy = moment(this.admissionDate.toDate()).format("YYYY");
        this.successDate = Number(yyyy);
      }
      const subjectSEarned = result.filter(m => m.overall_grade !== 'IP' && m.overall_grade !== 'I' && m.overall_grade !== 'W');
      this.creditAttempt = MappingService.sumCreditAttempted(result);
      const subjectEarned = subjectSEarned;
      this.totalCredit = MappingService.sumCreditCalculator(subjectEarned);
      this.creditEarned = MappingService.sumCreditEarned(subjectEarned);
      const subjectTotalPoint = subjectEarned.map(item => { return { total: item.gradePoint * item.credits_calculator } });
      this.totalPoint = MappingService.sumTotalPoint(subjectTotalPoint);
      this.totalGPA = this.totalPoint / this.totalCredit;

      const subjectPointP = subjectEarned.filter(m => m.overall_grade === 'P')
      if (subjectPointP && subjectPointP.length > 0) {
        const creditEarnedTotal = ConvertService.sum(subjectPointP, "credits_earned")
        this.totalGPA = this.totalPoint / (this.totalCredit - creditEarnedTotal);
      }

      const t = MappingService.groupBy(result, "term.key", "term.startDateKey");
      this.studentTerm = t;
      this.empty = result.length === 0;
      this.loading = false;
    })
  }

  updateCreditCal(studentKey, admissionKey, enrollKey, credits_attempted, callback) {
    this.ds.studentTranscriptDocRef(studentKey, admissionKey, enrollKey).update({
      credits_calculator: credits_attempted,
      credits_earned: credits_attempted,
    }).then(() => {
      callback(true, null);
    }).catch(error => {
      callback(false, error);
    })
  }
}
